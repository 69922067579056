import 'destyle.css/destyle.css'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCarousel from 'vue-carousel';
import VueGtag from 'vue-gtag';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileInvoiceDollar, faTimesCircle, faCheckCircle, faCheck, faAddressCard, faAngleRight, faPen } from '@fortawesome/free-solid-svg-icons'
import CKEditor from '@ckeditor/ckeditor5-vue2';

// Font Awesome Library
library.add(faFileInvoiceDollar)
library.add(faTimesCircle)
library.add(faCheckCircle)
library.add(faCheck)
library.add(faAddressCard)
library.add(faAngleRight)
library.add(faPen)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(VueCarousel);
Vue.use(CKEditor);

const GTAG = 'UA-162609878-1';
Vue.use(VueGtag, {
  config: { id: GTAG },
  enabled: process.env.VUE_APP_GATRACK
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
