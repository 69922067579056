import errorMessagesJson from '@/data/ErrorMessages.json'
const localization = 'en';
var ErrorMessages = {
    get(key){
        if(key !== ''){
            return errorMessagesJson[key][localization];
        }else{
            return '';
        }
    }
}
export default ErrorMessages;


