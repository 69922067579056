import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ResetPassword from '../views/ResetPassword.vue'
import store from '@/store'
import AngelaAPI from '@/utils/AngelaAPI'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/confirm/:token',
    name: 'HomeConfirm',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dashboard',
    redirect: {
      name: "Dashboard", params: { page: "listings" }
    }
  },
  {
    path: '/dashboard/:page',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkout/success',
    name: 'PaymentSuccess',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Payment_success.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/*',
    name: '404',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  AngelaAPI.authToken = store.state.auth.token;
  AngelaAPI.authenticationCheck().then((response) => {
    store.commit('setAuthenticatedUser', response.data.data)
    if (to.path === '/') {
      router.push({ name: "Dashboard", params: { page: "listings" } })
    } else {
      next();
    }
  })
    .catch((error) => {
      if (to.meta.requiresAuth) {
        router.push('/')
      } else {
        next();
      }
    });
})

export default router
