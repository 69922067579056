import Helpers from '@/utils/Helpers'
const axios = require('axios');
const baseUrl = process.env.VUE_APP_ANGELA;

var AngelaAPI = {

    authToken: '',

    /*
        Settings
    */
    getDashboardSettings() {
        return axios({
            url: urlBuilder(`/api/settings/dashboard-settings`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    getAgreements() {
        return axios({
            url: urlBuilder(`/api/agreements`),
            method: 'get'
        })
    },

    updateDashboardSettings(settings) {
        return axios({
            url: urlBuilder(`/api/settings/update-admin-settings`),
            data: {
                settings: settings
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'put'
        })
    },

    /*
        Users
    */
    listUsers(filters) {
        return axios({
            url: urlBuilder(`/api/account/users${userFilterBuilder(filters)}`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    updateUser(changes) {
        return axios({
            url: urlBuilder(`/api/account/update`),
            data: {
                user: changes
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'put'
        })
    },

    /*
        Profile User
    */
    getProfile() {
        return axios({
            url: urlBuilder(`/api/profile`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },
    updateProfile(profile) {
        return axios({
            url: urlBuilder(`/api/profile/update`),
            data: {
                profile
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'put'
        })
    },

    /*
        Profile Admin
    */
    updateProfileKvkStatus(userId, status) {
        return axios({
            url: urlBuilder(`/api/account/users/${userId}/profile/admin`),
            data: {
                profile: {
                    company_kvk_valid: status,
                }
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'put'
        })
    },

    /*
        Payments
    */
    getSubscriptionProducts() {
        return axios({
            url: urlBuilder(`/api/products/list`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        });
    },
    createPayment(productId) {
        return axios({
            url: urlBuilder(`/api/payment/create`),
            data: {
                productId
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'post'
        })
    },

    /*
        Subscription
    */
    createSubscription(userId, subscription) {
        return axios({
            url: urlBuilder(`/api/account/subscription/create`),
            data: {
                userId: userId,
                subscription: {
                    months: subscription.months,
                    status: subscription.status,
                    type: subscription.type,
                    invoiceNumber: subscription.invoiceNumber
                }
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'post'
        });
    },

    updateSubscription(subscriptionId, subscription) {
        return axios({
            url: urlBuilder(`/api/account/subscription/${subscriptionId}`),
            data: {
                subscription
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'put'
        })
    },

    // startSubscription() {
    //     return axios({
    //         url: urlBuilder(`/api/account/subscription/start`),
    //         headers: { Authorization: `Bearer ${this.authToken}` },
    //         method: 'get'
    //     })
    // },

    /*
        Account
    */
    login(credentials) {
        return axios.post(urlBuilder('/api/account/login'), {
            credentials: credentials
        })
    },

    register(registration) {
        return axios.post(urlBuilder('/api/account/register'), {
            registration: {
                user: {
                    email: registration.email,
                    password: registration.password,
                    password_confirmation: registration.passwordConfirm,
                    subscription_type: "trial",
                    agreements_accepted: registration.agreements
                },
                profile: {
                    first_name: registration.firstName,
                    last_name: registration.lastName,
                    phonenumber: registration.phonenumber,
                    company_name: registration.companyName,
                    company_kvk: registration.companyKvk,
                    billing_address: registration.address,
                    billing_address_number: registration.addressNumber,
                    billing_city: registration.city,
                    billing_country: registration.country
                }
            }
        })
    },

    resendConfirmationEmail(email) {
        return axios.post(urlBuilder('/api/account/resend-confirmation'), {
            email: email
        })
    },

    confirmEmail(token) {
        return axios.post(urlBuilder('/api/account/confirm'), {
            token: token
        })
    },

    sendResetPasswordMail(email) {
        return axios.post(urlBuilder('/api/account/reset-password'), {
            email: email
        })
    },

    resetPassword(token, reset) {
        return axios.post(urlBuilder('/api/account/reset-password-confirm'), {
            change: {
                token: token,
                password: reset.password,
                password_confirm: reset.passwordConfirm
            }
        })
    },

    authenticationCheck() {
        return axios({
            url: urlBuilder('/api/account/logged'),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    logout() {
        return axios({
            url: urlBuilder('/api/account/logout'),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'post'
        })
    },

    /*
        Locations
    */
    listDistricts(filters) {
        return axios({
            url: urlBuilder('/api/address/list-districts'),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    /*
        Agencies
    */
    listAgencies(filters) {
        return axios({
            url: urlBuilder('/api/agency/list'),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    /*
        Houses
    */
    listHouses(filters) {
        return axios({
            url: urlBuilder(`/api/house/list${houseFilterBuilder(filters)}`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    /*
        Filters
    */
    listFilters() {
        return axios({
            url: urlBuilder('/api/search/list'),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    getFilter(filterId) {
        return axios({
            url: urlBuilder(`/api/search/${filterId}`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    createFilter(filterData) {
        return axios({
            url: urlBuilder('/api/search/create'),
            data: {
                search: {
                    name: filterData.name,
                    query: filterData.query,
                    notify_by_email: filterData.recieveNotification
                }
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'post'
        })
    },

    updateFilter(filterId, filterData) {
        return axios({
            url: urlBuilder(`/api/search/${filterId}`),
            data: {
                search: {
                    name: filterData.name,
                    query: filterData.query,
                    notify_by_email: filterData.recieveNotification
                }
            },
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'put'
        })
    },

    deleteFilter(filterId) {
        return axios({
            url: urlBuilder(`/api/search/${filterId}`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'delete'
        })
    },

    /*
        statistics
    */
    getDistrictsMonthlyData(startDate) {
        if (startDate !== '') { startDate = `?start_date=${startDate}`; }
        return axios({
            url: urlBuilder(`/api/statistics/districts-monthly${startDate}`),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    },

    getWeeklyData() {
        return axios({
            url: urlBuilder('/api/statistics/weekly-data'),
            headers: { Authorization: `Bearer ${this.authToken}` },
            method: 'get'
        })
    }
}
export default AngelaAPI;

function urlBuilder(path) {
    return `${baseUrl}${path}`;
}

function userFilterBuilder(filters) {

    const queries = [];

    // Filters
    if (filters.search !== '') { queries.push(`&search=${filters.search}`); }

    // Sorting
    if (filters.sorting.sortBy !== '') {
        const sortBy = filters.sorting.sortBy;
        queries.push(`&sort_by=${sortBy}`);
        queries.push(`&sort_direction=${filters.sorting.sortDirection}`);
    }

    if (queries.length > 0) {
        queries[0] = queries[0].replace('&', '?');
    }
    return queries.join('');
}

function houseFilterBuilder(filters) {
    const queries = [];
    // TODO: Need a smarter way to do this

    // Filters
    if (filters.contract !== '') { queries.push(`&contract=${filters.contract}`); }
    if (filters.search !== '') { queries.push(`&search=${encodeURIComponent(filters.search)}`); }
    if (filters.country !== '') { queries.push(`&country=${filters.country}`); }
    if (filters.agency !== '') { queries.push(`&agency=${encodeURIComponent(filters.agency)}`); }
    if (filters.bedrooms !== '') { queries.push(`&bedrooms=${Helpers.getNumbersOnly(filters.bedrooms)}`); }
    if (filters.bathrooms !== '') { queries.push(`&bathrooms=${Helpers.getNumbersOnly(filters.bathrooms)}`); }
    if (filters.reduced !== '') { queries.push('&reduced=true'); }
    if (filters.increased !== '') { queries.push('&increased=true'); }
    if (filters.pool !== '') { queries.push('&pool=true'); }
    if (filters.propertyType !== '') { queries.push(`&property_type=${filters.propertyType}`); }
    if (filters.parcelType !== '') { queries.push(`&parcel_type=${filters.parcelType}`); }
    if (filters.daysListed !== '') { queries.push(`&days_listed=${Helpers.getNumbersOnly(filters.daysListed)}`); }
    queries.push('&status=open');

    // Min & Max Lot
    if (filters.lotSize.min !== "") { queries.push(`&min_lot_size=${filters.lotSize.min}`); }
    if (filters.lotSize.max !== "") { queries.push(`&max_lot_size=${filters.lotSize.max}`); }

    // Dirticts
    if (filters.districts.length > 0) {
        queries.push(`&districts=${filters.districts.join()}`);
    }

    // Pricing
    // Min & Max Lot
    if (filters.priceRange.min !== "") { queries.push(`&min_price=${filters.priceRange.min}`); }
    if (filters.priceRange.max !== "") { queries.push(`&max_price=${filters.priceRange.max}`); }

    // Sorting
    if (filters.sorting.sortBy !== '') {
        let sortBy = filters.sorting.sortBy;
        if (sortBy === 'date_posted_listed') {
            sortBy = 'date_posted'
        }
        queries.push(`&sort_by=${sortBy}`);
        queries.push(`&sort_direction=${filters.sorting.sortDirection}`);
    }

    // Pagination
    queries.push(`&page=${filters.pagination.page}`);
    queries.push(`&limit=${filters.pagination.limit}`);

    if (queries.length > 0) {
        queries[0] = queries[0].replace('&', '?');
    }

    return queries.join('');
}
