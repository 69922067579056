<template>
  <div class="home-login">
    <div class="home-login-signin">
      <div class="home-login-signin-header">
        <h2>Sign in</h2>
        <p v-on:click="openContact()">Contact us</p>
      </div>

      <ErrorBox ref="errorBox" />

      <TextInput
        placeholder="Email"
        inputName="email"
        inputType="text"
        v-model="credentials.email"
      />

      <TextInput
        placeholder="Password"
        inputName="password"
        inputType="password"
        v-model="credentials.password"
      />

      <div class="home-login-signin-tools">
        <p v-on:click="openForgotPass()">Forgot Password</p>
      </div>

      <div class="home-login-signin-button">
        <img v-show="loading" class="spinner" src="../../assets/images/spinner.svg" />

        <MercalaButton v-on:button-event="sendSignIn()" label="Sign in" type="ok" />
      </div>
    </div>

    <div class="home-login-signup">
      <h2>Create Account</h2>
      <p>Sign up and try it!</p>

      <ArrowButton v-on:button-event="signUp()" label="Sign up" arrowDirection="right" />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import MercalaButton from "@/components/form/MercalaButton";
import ArrowButton from "@/components/form/ArrowButton";
import ErrorBox from "@/components/form/ErrorBox";
import AngelaAPI from "@/utils/AngelaAPI";

export default {
  name: "LoginForm",
  components: {
    TextInput,
    MercalaButton,
    ArrowButton,
    ErrorBox
  },
  data() {
    return {
      loading: false,
      credentials: {
        email: "",
        password: ""
      },
      errorMessage: ""
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onEnterKey);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onEnterKey);
  },
  methods: {
    onEnterKey(event) {
      if (event.keyCode === 13) {
        this.sendSignIn();
      }
    },
    sendSignIn() {
      this.$refs.errorBox.clearError();
      this.loading = true;
      AngelaAPI.login(this.credentials)
        .then(response => {
          this.$store.commit(
            "setAuthenticationToken",
            response.data.data.token
          );
          this.$router.push({
            name: "Dashboard",
            params: { page: "listings" }
          });
        })
        .catch(error => {
          this.loading = false;
          if (error.response.status === 400) {
            if (error.response.data.data === "CONFIRMATION_REQUIRED") {
              this.$refs.errorBox.setError(
                "Your email needs to be confirmed, we have sent you an email with a confirmation link!"
              );
            } else {
              this.$refs.errorBox.setError("Wrong email and/or password");
            }
          } else {
            this.$refs.errorBox.setError(
              "Something went wrong, please try again later."
            );
          }
        });
    },
    openForgotPass() {
      this.$emit("change-view", "resetpassword");
    },
    openContact() {
      this.$emit("change-view", "contact");
    },
    signUp() {
      this.$emit("change-view", "signup");
    }
  }
};
</script>

<style lang="scss">
.home-login {
  width: 100%;
  padding-left: 75px;
  padding-right: 75px;
  margin: 140px auto 0px auto;

  &-signin {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;

    .error-box {
      margin-top: 15px;
    }

    &-button {
      display: block;
      margin: 10px 0px 0px auto;
      position: relative;

      .spinner {
        position: absolute;
        width: 60px;
        right: 128px;
        top: -1px;
      }

      .mercala-button {
        display: inline-block;
      }
    }

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      p {
        margin-top: 5px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    &-tools {
      margin-top: 15px;
      margin-bottom: 5px;
      width: 100%;
      text-align: right;
      p {
        display: inline;
        cursor: pointer;
        color: #979797;
        text-decoration: underline;
        font-size: 0.85rem;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &-signup {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;

    h2 {
      margin-bottom: 10px;
    }

    .arrow-button {
      margin-left: auto;
      margin-top: 20px;
      margin-right: 15px;
    }
  }
}
</style>
