<template>
  <div class="home-contact">
    <div class="home-contact-container">
      <div class="home-contact-container-header">
        <h2>Contact</h2>
        <p v-on:click="backToLogin()">Sign in</p>
      </div>

      <p>If you have any questions or feedback, please let us know at:</p>

      <p>
        <span>Email adres</span> <br />
        <a href="mailto:info@mercala.org">info@mercala.org</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContact",
  methods: {
    backToLogin() {
      this.$emit("change-view", "signin");
    },
  },
};
</script>

<style lang="scss">
.home-contact {
  width: 100%;
  padding-left: 75px;
  padding-right: 75px;
  margin: 200px auto 0px auto;
  position: relative;

  &-container {
    p {
      margin-bottom: 25px;
      line-height: 1.3rem;
      span {
        font-weight: 600;
      }
    }

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #d8d8d8;
      p {
        margin-top: 0px;
        margin-bottom: 3px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
