<template>
  <div :class="mainClasses">
        <button 
            :disabled="disabled"
            v-on:click="buttonClicked()"
            :class="buttonClasses()">{{ label }}</button>
  </div>
</template>

<script>
export default {
  name: "MercalaButton",
  props: {
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "ok",
    },
    extraClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    mainClasses() {
      return ["mercala-button", this.extraClasses];
    },
  },
  data() {
    return {
      inputVvalue: "",
      disabled: false,
    };
  },
  methods: {
    buttonClasses() {
      const classes = {
        "mercala-button-btn": true,
      };
      /* 
                Options:
                - ok
                - cancel
            */
      classes[`mercala-button-btn-${this.$props.type}`] = true;
      return classes;
    },
    buttonClicked() {
      this.$emit("button-event");
    },
    setDisabled(state) {
      this.disabled = state;
    },
  },
};
</script>

<style lang="scss">
.mercala-button {
  .mercala-button-btn {
    margin: 5px;
    padding: 15px 35px;
    border-radius: 25px;
    font-size: 1rem;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        opacity: 0.5;
      }
    }

    &-ok {
      background: rgb(255, 30, 30);
      background: linear-gradient(
        -90deg,
        rgba(255, 30, 30, 1) 0%,
        rgba(254, 90, 51, 1) 100%
      );
      color: white;
      &:hover {
        opacity: 0.8;
      }
    }

    &-cancel {
      background: #f3f3f3;
      color: #979797;
      &:hover {
        background: #979797;
        color: white;
      }
    }

    &-dashboard-ok {
      background: rgb(255, 30, 30);
      background: linear-gradient(
        -90deg,
        rgba(255, 30, 30, 1) 0%,
        rgba(254, 90, 51, 1) 100%
      );
      color: white;
      height: 40px;
      font-size: 0.875rem;
      padding: 0px 35px;
      &:hover {
        opacity: 0.8;
      }
    }

    &-dashboard-cancel {
      background: #f3f3f3;
      color: #979797;
      height: 40px;
      font-size: 0.875rem;
      padding: 0px 35px;
      &:hover {
        background: #979797;
        color: white;
      }
    }
  }
}
</style>
