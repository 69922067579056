const searchBlacklist = [
    "search",
    "pagination",
    "sorting",
    "country",
    "min_amount",
    "max_amount",
    "district"
];

var Helpers = {

    showSorting(context, field) {
        const sorting = context.$store.getters.getFormValues("sorting");
        return {
            "table-sorting": true,
            "table-sorting-show": sorting.sortBy === field,
            "table-sorting-asc": sorting.sortDirection === "asc",
        };
    },

    setSorting(context, field) {
        const sorting = context.$store.getters.getFormValues("sorting");
        if (sorting.sortBy === field) {
            if (sorting.sortDirection === "asc") {
                sorting.sortBy = "";
                sorting.sortDirection = "";
            } else {
                sorting.sortDirection = "asc";
            }
        } else {
            sorting.sortBy = field;
            sorting.sortDirection = "desc";
        }
        context.$store.commit("setFormValues", { form: "sorting", val: sorting });
    },

    addCommasToNumber(nStr) {
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        var res = x1 + x2
        return res.split('.')[0];
    },

    getNumbersOnly(str) {
        return str.replace(/\D/g, '');
    },

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    prefillFilterForm(context, selectedFilter, filters) {
        const savedFilter = selectedFilter;
        const queries = Object.keys(savedFilter.query);
        const filterRefs = {
            agency: {
                ref: context.$refs.agencyFilter,
                key: "agency"
            },
            contract: {
                ref: context.$refs.contractFilter,
                key: "contract"
            }
        };

        let pool = "";
        let reduced = "";
        let increased = "";
        let daysListed = "";
        let districts = [];
        let propertyType = "";
        let parcelType = "";
        let lotSize = {
            min: "",
            max: ""
        };
        let priceRange = {
            min: "",
            max: ""
        };

        // Reset values
        context.$refs.lotSizeFilter.setValue(lotSize);
        context.$refs.priceRangeFilter.setValue(priceRange);

        for (var i = 0; i < queries.length; i++) {
            switch (queries[i]) {
                case "bathrooms":
                    filters.bathrooms = savedFilter.query.bathrooms;
                    for (let k = 0; k < context.$refs.bathroomFilter.length; k++) {
                        context.$refs.bathroomFilter[k].setValue(
                            savedFilter.query.bathrooms
                        );
                    }
                    break;
                case "bedrooms":
                    filters.bedrooms = savedFilter.query.bedrooms;
                    for (let k = 0; k < context.$refs.bedroomFilter.length; k++) {
                        context.$refs.bedroomFilter[k].setValue(savedFilter.query.bedrooms);
                    }
                    break;
                case "days_listed":
                    if (savedFilter.query.days_listed === 180) {
                        daysListed = `+${savedFilter.query.days_listed}`;
                    } else {
                        daysListed = savedFilter.query.days_listed;
                    }
                    filters.daysListed = daysListed;
                    for (let k = 0; k < context.$refs.listedFilter.length; k++) {
                        context.$refs.listedFilter[k].setValue(daysListed);
                    }
                    break;
                case "property_type":
                    if (savedFilter.query.property_type !== "") {
                        propertyType = savedFilter.query.property_type
                    }
                    filters.propertyType = propertyType;
                    context.$refs.propertyTypeFilter.setValue(propertyType);
                    break;
                case "parcel_type":
                    if (savedFilter.query.parcel_type !== "") {
                        parcelType = savedFilter.query.parcel_type
                    }
                    filters.parcelType = parcelType;
                    context.$refs.parcelTypeFilter.setValue(parcelType);
                    break;
                case "reduced":
                    if (savedFilter.query.reduced) {
                        reduced = "Yes";
                    }
                    filters.reduced = reduced;
                    context.$refs.reducedFilterYes.setValue(reduced);
                    break;
                case "increased":
                    if (savedFilter.query.increased) {
                        increased = "Yes";
                    }
                    filters.increased = increased;
                    context.$refs.increasedFilterYes.setValue(increased);
                    break;
                case "pool":
                    if (savedFilter.query.pool) {
                        pool = "Yes";
                    }
                    filters.pool = pool;
                    context.$refs.poolFilter.setValue(pool);
                    break;
                case "districts":
                    if (savedFilter.query.districts !== "") {
                        districts = savedFilter.query.districts.split(",");
                    }
                    filters.districts = districts;
                    context.$refs.districtsFilter.setValue(districts);
                    break;
                case "lotSize":
                    lotSize = savedFilter.query.lotSize
                    filters.lotSize = lotSize;
                    context.$refs.lotSizeFilter.setValue(lotSize);
                    break;
                case "priceRange":
                    priceRange = savedFilter.query.priceRange
                    filters.priceRange = priceRange;
                    context.$refs.priceRangeFilter.setValue(priceRange);
                    break;
                default:
                    if (!searchBlacklist.includes(queries[i])) {
                        filters[filterRefs[queries[i]].key] =
                            savedFilter.query[queries[i]];

                        if (filterRefs[queries[i]].ref) {
                            filterRefs[queries[i]].ref.setValue(
                                savedFilter.query[queries[i]]
                            );
                        }
                    }
                    break;
            }
        }
    },

    saveSearchPayloadCreater(selectedFilters) {

        const queryItems = {};
        const filterKeys = Object.keys(selectedFilters);
        const filters = selectedFilters;

        for (var i = 0; i < filterKeys.length; i++) {
            const key = filterKeys[i];

            if (!searchBlacklist.includes(key)) {
                switch (key) {
                    case "districts":
                        queryItems.districts = filters.districts.join();
                        break;
                    case "daysListed":
                        queryItems.days_listed = filters[key];
                        break;
                    case "propertyType":
                        queryItems.property_type = filters[key];
                        break;
                    case "parcelType":
                        queryItems.parcel_type = filters[key];
                        break;
                    default:
                        queryItems[key] = filters[key];
                        break;
                }
            }
        }

        return queryItems;
    },

    filterTagGenerator(appliedFilters) {
        const filters = [];
        const af = appliedFilters;

        if (af.propertyType !== '') { filters.push(af.propertyType); }
        if (af.country !== '') { filters.push(af.country); }
        if (af.agency !== '') { filters.push(af.agency); }
        if (af.bedrooms !== '') { filters.push(`${af.bedrooms} bedrooms`); }
        if (af.bathrooms !== '') { filters.push(`${af.bathrooms} bathrooms`); }
        if (af.reduced !== '') { filters.push('In reduced price'); }
        if (af.increased !== '') { filters.push('In increased price'); }
        if (af.pool !== '') { filters.push('With pool'); }
        if (af.contract !== '') { filters.push(`For ${this.capitalizeFirstLetter(af.contract)}`); }

        if (af.lotSize.min !== "") { filters.push(`Min lot size: ${af.lotSize.min}`); }
        if (af.lotSize.max !== "") { filters.push(`Max lot size: ${af.lotSize.max}`); }

        if (af.parcelType !== '') {
            if (af.parcelType === "property land") {
                filters.push("Freehold");
            }

            if (af.parcelType === "long lease land") {
                filters.push("Leasehold");
            }
        }

        if (af.districts.length > 0) {
            for (var i = 0; i < af.districts.length; i++) {
                filters.push(af.districts[i]);
            }
        }

        if (af.daysListed !== '') {
            const daysListed = this.getNumbersOnly(af.daysListed);
            if (daysListed >= 180) {
                filters.push(`More than ${daysListed} days listed`);
            } else {
                filters.push(`Up to ${daysListed} days listed`);
            }
        }

        // Price range
        if (af.priceRange.min !== "") { filters.push(`Min price: ${af.priceRange.min}`); }
        if (af.priceRange.max !== "") { filters.push(`Max price: ${af.priceRange.max}`); }


        // Clear empty values
        return filters.filter(b => b !== undefined);
    }
}
export default Helpers;
