<template>
  <div class="home">
    <img
      src="../assets/images/Mercala_logo_red.svg"
      :class="logoClasses()"
      alt="Mercala Logo"
    />

    <Lightbox v-if="showLightbox" :lightbox="lightboxData" />

    <!-- Homepage gallery -->
    <div
      :class="introductionClasses()"
      v-if="this.rightSideForms.includes(this.showView)"
    >
      <div class="home-introduction-image-container"></div>
      <div class="home-introduction-text">
        <img
          src="../assets/images/introduction_corner.svg"
          alt="Corner image"
        />
        <h1>All Real Estate Listings on Aruba</h1>
        <p>
          Find the house you are looking for fast and easy! <br />
          No more tedious scrolling through countless webpages. <br />
          Be the <i><b>first</b></i> to know about new listings with real-time
          data.
        </p>
      </div>
    </div>

    <!-- Signup details -->
    <div :class="detailsClasses()" v-if="this.showView === 'signup'">
      <div
        class="home-signup-details-image-container"
        v-if="!this.confirmationShow"
      ></div>
      <div
        class="home-signup-details-image-container home-signup-details-image-container-confirm"
        v-if="this.confirmationShow"
      ></div>
      <div class="home-signup-details-text" v-if="!this.confirmationShow">
        <img src="../assets/images/sign_up_corner.svg" alt="Corner image" />
        <h1>At Mercala we believe in Data-driven decision making.</h1>
        <p>
          Our vision is a transparent real estate market! <br />
          Are you Mercala?
        </p>
      </div>
    </div>

    <!-- account section -->
    <div :class="formContainerClasses()">
      <div class="home-form-container">
        <LoginForm
          v-on:change-view="switchForm"
          v-if="this.showView === 'signin'"
        />

        <ResetForm
          v-on:change-view="switchForm"
          v-if="this.showView === 'resetpassword'"
        />

        <Contact
          v-on:change-view="switchForm"
          v-if="this.showView === 'contact'"
        />

        <RegisterForm
          v-on:change-view="switchForm"
          v-on:open-agreements="openAgreements"
          :confirmation="confirmation"
          :token="token"
          v-if="this.showView === 'signup'"
        />

        <div class="home-form-container-footer">
          <p>&copy;2020 All Rights Reserved.</p>
        </div>
      </div>
    </div>

    <div class="home-cookie" v-if="this.showCookie">
      <p>
        We use cookies to ensure that we give you the best experience on our
        website.
      </p>
      <button v-on:click="hideCookie()">GOT IT</button>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/home/LoginForm";
import RegisterForm from "@/components/home/RegisterForm";
import ResetForm from "@/components/home/ResetForm";
import Contact from "@/components/home/Contact";
import { Lightboxes } from "@/utils/Constants";
import { EventBus } from "@/utils/EventBus.js";
export default {
  name: "Home",
  components: {
    LoginForm,
    RegisterForm,
    ResetForm,
    Contact,
    Lightbox: () => import("@/components/lightboxes/Lightbox"),
  },
  data() {
    return {
      showLightbox: false,
      lightboxData: {},
      rightSideForms: ["signin", "resetpassword", "contact"],
      view: "signin",
      showView: "signin",
      confirmation: false,
      confirmationShow: false,
      animateForm: false,
      token: "",
      showCookie: true,
    };
  },
  methods: {
    openAgreements(terms) {
      this.lightboxData = {
        view: Lightboxes.Agreements,
        data: {
          terms: terms,
          refreshText: true,
          showButtons: false,
        },
      };
      this.showLightbox = true;
    },
    hideCookie() {
      this.showCookie = false;
    },
    switchForm(view) {
      if (view === "signup-from-confirmation") {
        this.confirmation = false;
        this.animateForm = false;
        view = "signup";
      } else {
        this.animateForm = true;
      }

      this.view = view;

      if (this.view === "signin" && this.confirmation) {
        this.confirmation = false;
      }

      setTimeout(() => {
        if (this.view === "signin" && !this.confirmation) {
          this.confirmationShow = false;
        }
        this.showView = view;
      }, 250);

      setTimeout(() => {
        this.animateForm = false;
      }, 500);
    },
    formContainerClasses() {
      return {
        "home-form": true,
        "home-form-signin": this.rightSideForms.includes(this.view),
        "home-form-signup": this.view === "signup",
        "home-form-animate": this.animateForm,
      };
    },
    introductionClasses() {
      return {
        "home-introduction": true,
        "home-introduction-hide": this.view === "signup",
      };
    },
    detailsClasses() {
      return {
        "home-signup-details": true,
        "home-signup-details-hide": this.rightSideForms.includes(this.view),
      };
    },
    logoClasses() {
      return {
        logo: true,
      };
    },
  },
  mounted() {
    if (Object.keys(this.$route.params).includes("token")) {
      this.view = "signup";
      this.showView = "signup";
      this.confirmation = true;
      this.confirmationShow = true;
      this.token = this.$route.params.token;
    }

    EventBus.$on("close-lightbox", () => {
      this.showLightbox = false;
    });
  },
  destroyed() {
    EventBus.$off("close-lightbox", () => {
      this.showLightbox = false;
    });
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  overflow: auto;

  img.logo {
    width: 150px;
    position: absolute;
    top: 12px;
    left: 7px;
    transition: opacity 0.25s ease-in;
    opacity: 1;
    z-index: 2;
    background-color: white;
    padding: 11px 20px;
    border-radius: 15px;
  }

  &-signup-details,
  &-introduction {
    width: calc(100vw - 500px);
    height: 100%;
    margin-left: 0px;
    transition: opacity 0.25s ease-in;
    opacity: 1;
    padding: 25px;
    position: relative;

    &-hide {
      opacity: 0;
    }

    &-text {
      background-color: white;
      position: absolute;
      bottom: 0px;
      padding: 30px 90px 50px 30px;
      border-radius: 30px;
      width: 550px;

      h1 {
        font-weight: 600;
        margin-bottom: 15px;
        color: #ff1e1e;
        font-size: 2.25rem;
      }

      p {
        width: 450px;
        line-height: 1.5rem;
        color: black;
        font-size: 1rem;
      }
    }

    &-image-container {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 30px;
    }
  }

  &-signup-details {
    margin-left: 500px;

    &-text {
      left: 0px;
      padding-left: 50px;
      img {
        position: absolute;
        top: -29px;
        left: 25px;
      }
    }

    &-image-container {
      background-image: url(../assets/images/signup_photo.jpg);
    }

    &-image-container-confirm {
      background-image: url(../assets/images/confirm_photo.jpg);
    }
  }

  &-introduction {
    margin-left: 0px;

    &-text {
      right: 0px;
      img {
        position: absolute;
        top: -29px;
        right: 25px;
      }
    }

    &-image-container {
      background-image: url(../assets/images/introduction_photo.jpg);
    }
  }

  &-form {
    position: relative;
    width: 500px;
    height: 100vh;
    background-color: white;
    position: absolute;
    top: 0px;
    transition: transform 0.5s ease-in-out;

    &-container {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow-y: auto;

      &-footer {
        margin: auto auto 15px 75px;

        p {
          color: #979797;
          font-size: 0.75rem;
        }
      }
    }

    &-signin {
      transform: translate(calc(100vw - 500px), 0px);
    }

    &-signup {
      transform: translate(0px, 0px);
    }

    &-animate {
      .home-form-container {
        animation-name: formAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
      }
    }
  }

  &-cookie {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 45px;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    font-size: 0.75rem;

    button {
      border: 1px solid #979797;
      padding: 5px 10px;
      border-radius: 15px;
      margin-left: 25px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@keyframes formAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
