<template>
    <div :class="errorBoxClasses()">
        <h3>Oh-oh</h3>
        <p>{{ this.message }}</p>
    </div>
</template>

<script>

export default {
    name: 'ArrowButton',
    data(){
        return {
            message: '',
            show: false
        }
    },
    methods: {
        setError(message){
            this.message = message;
            this.show = true;
        },
        clearError(){
            this.message = '';
            this.show = false;
        },
        errorBoxClasses(){
            return {
                'error-box': true,
                'error-box-show': this.show
            };
        }
    }
}
</script>

<style lang="scss">

.error-box{
    width: 100%;
    background-color: #ffe9e9;
    padding: 15px;
    border-radius: 10px;
    display: none;

    h3, p {
        font-size: 0.75rem;
    }

    p{
        line-height: 1rem;
    }

    h3{
        font-weight: 550;
        margin-bottom: 5px;
    }

    &-show{
        display: block;
    }
}

</style>
