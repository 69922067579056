<template>
    <div class="arrow-button">
        <button 
            v-on:click="buttonClicked()"
            :class="buttonClasses()">{{ label }}</button>
    </div>
</template>

<script>

export default {
    name: 'ArrowButton',
    props: {
        label: {
            type: String,
            default: ''
        },
        arrowDirection: {
            type: String,
            default: 'right'
        }
    },
    data(){
        return {
            inputVvalue: ''
        }
    },
    methods: {
        buttonClasses(){
            return {
                'arrow-button-btn': true,
                'arrow-button-btn-right': (this.$props.arrowDirection === 'right'),
                'arrow-button-btn-left': (this.$props.arrowDirection === 'left')
            };
        },
        buttonClicked(){
            this.$emit('button-event')
        }
    }
}
</script>

<style lang="scss">

.arrow-button{
    .arrow-button-btn{
        margin: 5px;
        padding: 10px 20px;
        border-radius: 20px;
        position: relative;

        &:after{
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            background-image: url(../../assets/images/right_arrow.svg);
            background-repeat: no-repeat;
            background-size: 25px;
            top: 7px;

            svg{
                width: 5px;
            }
        }

        &-right{
            &:after{
                right: -20px;
            }
        }

        &-left{
            &:after{
                left: -20px;
                transform: rotate(180deg);
            }
        }
    }
}

</style>
