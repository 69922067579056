<template>
  <div class="home-register">
    <div class="home-register-back">
      <ArrowButton
        v-on:button-event="backToLogin()"
        label="Sign in"
        arrowDirection="left"
      />
    </div>

    <template v-if="!registerSuccess && !confirmation">
      <div class="home-register-form">
        <h2>Sign up</h2>

        <div class="home-register-form-breadcrumbs">
          <ul>
            <li :class="breadcrumbClasses(1)">
              Contact <font-awesome-icon :icon="['fas', 'angle-right']" />
            </li>
            <!-- <li :class="breadcrumbClasses(2)">
              Company <font-awesome-icon :icon="['fas', 'angle-right']" />
            </li> -->
            <li :class="breadcrumbClasses(3)">Account</li>
          </ul>
        </div>

        <ErrorBox ref="errorBox" />

        <!-- Contact Form -->
        <div
          class="home-register-form-section contact-form"
          v-show="currentForm === 'contact-form'"
        >
          <h3>Contact</h3>

          <div class="home-register-form-row">
            <TextInput
              ref="registrationFirstName"
              placeholder="First name"
              inputType="text"
              inputName="first_name"
              v-model="registration.firstName"
            />

            <TextInput
              ref="registrationLastName"
              placeholder="Last name"
              inputType="text"
              inputName="last_name"
              v-model="registration.lastName"
            />
          </div>

          <TextInput
            ref="registrationPhonenumber"
            placeholder="Phonenumber"
            inputType="text"
            inputName="phonenumber"
            v-model="registration.phonenumber"
          />

          <div class="home-register-form-section-tools">
            <button v-on:click="changeForm('account-form', false)">
              Account
              <span>
                <font-awesome-icon :icon="['fas', 'angle-right']" />
              </span>
            </button>
          </div>
        </div>

        <!-- Company Form -->
        <!-- Disbaled for now. might hav to remove later -->
        <!-- <div
          class="home-register-form-section company-form"
          v-show="currentForm === 'company-form'"
        >
          <h3>Company</h3>

          <TextInput
            ref="registrationCompanyName"
            placeholder="Name"
            inputType="text"
            inputName="company_name"
            v-model="registration.companyName"
          />

          <TextInput
            ref="registrationCompanyKvk"
            placeholder="Registration Number"
            inputType="text"
            inputName="company_kvk"
            v-model="registration.companyKvk"
          />

          <div class="home-register-form-row home-register-form-2-1">
            <TextInput
              ref="registrationAddress"
              placeholder="Address"
              inputType="text"
              inputName="Address"
              v-model="registration.address"
            />

            <TextInput
              ref="registrationAddresssNumber"
              placeholder="Number"
              inputType="text"
              inputName="address_number"
              v-model="registration.addressNumber"
            />
          </div>

          <div class="home-register-form-row">
            <TextInput
              ref="registrationCity"
              placeholder="City"
              inputType="text"
              inputName="city"
              v-model="registration.city"
            />

            <TextInput
              ref="registrationCountry"
              placeholder="Country"
              inputType="text"
              inputName="contry"
              v-model="registration.country"
            />
          </div>

          <div class="home-register-form-section-tools">
            <button class="prev" v-on:click="changeForm('contact-form', true)">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-right']" />
              </span>
              Contact info
            </button>

            <button v-on:click="changeForm('account-form', false)">
              Account
              <span>
                <font-awesome-icon :icon="['fas', 'angle-right']" />
              </span>
            </button>
          </div>
        </div> -->

        <!-- Account Form -->
        <div
          class="home-register-form-section account-form"
          v-show="currentForm === 'account-form'"
        >
          <h3>Account</h3>

          <TextInput
            ref="registrationEmail"
            placeholder="Email Address"
            inputType="text"
            inputName="email"
            v-model="registration.email"
          />

          <TextInput
            ref="registrationPassword"
            placeholder="Password"
            inputType="password"
            inputName="password"
            v-model="registration.password"
          />

          <TextInput
            ref="registrationPasswordConfirm"
            placeholder="Confirm Password"
            inputType="password"
            inputName="passwordConfirm"
            v-model="registration.passwordConfirm"
          />

          <div class="home-register-form-section-agreements">
            <CheckboxButton
              ref="registrationAgreements"
              inputName="agreements"
              inputLabel=""
              v-model="registration.agreements"
            />

            <p>
              By checking this box, you have read and agree to our
              <span v-on:click="openAgreements('termsOfUse')"
                >Terms of Use</span
              >
              and
              <span v-on:click="openAgreements('privacyPolicy')"
                >Privacy Policy</span
              >
            </p>
          </div>

          <div class="home-register-form-section-tools">
            <button class="prev" v-on:click="changeForm('contact-form', true)">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-right']" />
              </span>
              Contact
            </button>

            <div class="home-register-button">
              <img
                v-show="loading"
                class="spinner"
                src="../../assets/images/spinner.svg"
              />

              <MercalaButton
                v-on:button-event="signUp()"
                label="Sign up"
                type="ok"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="registerSuccess && !confirmation">
      <div class="home-register-success">
        <h1>Your account is almost ready.</h1>

        <ErrorBox ref="errorBox" />

        <h2>Activate your account</h2>
        <p>
          Click on the link in the email we've send you. <br />
          Please check your spambox if you don't see the email.
        </p>

        <!-- TODO, Temporarily disabled
            <button v-on:click="resendMail()">I did not get an email.</button>
            <p :class="resendTextClasses()">Email has been resent!</p>
            -->
      </div>
    </template>

    <template v-if="confirmation">
      <div class="home-register-success">
        <div :class="confirmingClasses()">
          <h1>Confirming...</h1>
        </div>

        <div :class="confirmedClasses()">
          <h1 v-html="confirmationMessage.title"></h1>
          <p v-html="confirmationMessage.text"></p>

          <template v-if="confirmationMessage.signup">
            <button class="tosignup" v-on:click="openSignup()">Sign up</button>
          </template>

          <template v-if="confirmationMessage.showEmailInput">
            <TextInput
              ref="confirmationEmail"
              placeholder="Email Address"
              inputType="text"
              inputName="email"
              v-model="confirmationMessage.email"
            />

            <MercalaButton
              v-on:button-event="resendConfirmation()"
              label="Resend confirmation"
              type="ok"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import MercalaButton from "@/components/form/MercalaButton";
import ArrowButton from "@/components/form/ArrowButton";
import CheckboxButton from "@/components/form/CheckboxButton";
import AngelaAPI from "@/utils/AngelaAPI";
import ErrorBox from "@/components/form/ErrorBox";
import ErrorMessages from "@/utils/ErrorMessages";
export default {
  name: "RegisterForm",
  components: {
    TextInput,
    MercalaButton,
    ArrowButton,
    ErrorBox,
    CheckboxButton,
  },
  props: {
    confirmation: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentForm: "contact-form",
      registration: {
        email: "",
        password: "",
        passwordConfirm: "",
        firstName: "",
        lastName: "",
        phonenumber: "",
        companyName: "",
        companyKvk: "",
        address: "",
        addressNumber: "",
        city: "",
        country: "",
        agreements: false,
      },
      loading: false,
      registerSuccess: false,
      emailResend: false,
      confirmationSuccess: false,
      confirmationMessage: {
        title: "",
        text: "",
        showEmailInput: false,
        email: "",
        signup: false,
      },
      fields: [
        { field: "email", ref: "registrationEmail" },
        { field: "password", ref: "registrationPassword" },
        { field: "password_confirmation", ref: "registrationPasswordConfirm" },
        { field: "first_name", ref: "registrationFirstName" },
        { field: "last_name", ref: "registrationLastName" },
        { field: "phonenumber", ref: "registrationPhonenumber" },
        { field: "company_name", ref: "registrationCompanyName" },
        { field: "company_kvk", ref: "registrationCompanyKvk" },
        { field: "billing_address", ref: "registrationAddress" },
        { field: "billing_address_number", ref: "registrationAddresssNumber" },
        { field: "billing_city", ref: "registrationCity" },
        { field: "billing_country", ref: "registrationCountry" },
      ],
    };
  },
  computed: {
    currentStep() {
      let step = 1;
      switch (this.currentForm) {
        case "contact-form":
          step = 1;
          break;
        case "company-form":
          step = 2;
          break;
        case "account-form":
          step = 3;
          break;
      }

      return step;
    },
  },
  methods: {
    openAgreements(terms) {
      this.$emit("open-agreements", terms);
    },
    changeForm(formView, back) {
      // TODO: REDO ERROR CHECKS
      this.resetErrors();
      const emptyRefs = [];
      let errorsCount = 0;
      const errorTypes = [];
      let fieldChecks = [];

      if (back) {
        this.currentForm = formView;
        return;
      }

      const phoneRegExp = RegExp(
        "^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-s./0-9]*$",
        "g"
      );

      switch (this.currentForm) {
        case "contact-form":
          fieldChecks = [
            { field: "firstName", ref: "registrationFirstName" },
            { field: "lastName", ref: "registrationLastName" },
            { field: "phonenumber", ref: "registrationPhonenumber" },
          ];
          break;
        case "company-form":
          fieldChecks = [
            { field: "companyName", ref: "registrationCompanyName" },
            { field: "companyKvk", ref: "registrationCompanyKvk" },
            { field: "address", ref: "registrationAddress" },
            { field: "addressNumber", ref: "registrationAddresssNumber" },
            { field: "city", ref: "registrationCity" },
            { field: "country", ref: "registrationCountry" },
          ];
          break;
      }

      for (let i = 0; i < fieldChecks.length; i++) {
        if (this.registration[fieldChecks[i].field] === "") {
          emptyRefs.push(fieldChecks[i].ref);
          errorsCount++;
        }

        if (fieldChecks[i].field === "phonenumber") {
          if (!phoneRegExp.test(this.registration.phonenumber)) {
            errorTypes.push("phonenumber");
            errorsCount++;
          }
        }
      }

      if (errorsCount > 0) {
        for (let i = 0; i < emptyRefs.length; i++) {
          this.$refs[emptyRefs[i]].setError("required");
        }

        if (errorTypes.includes("phonenumber")) {
          this.$refs.registrationPhonenumber.setError("Invalid Phonenumber");
        }
      } else {
        this.currentForm = formView;
      }
    },
    onEnterKey(event) {
      if (event.keyCode === 13) {
        this.signUp();
      }
    },
    openSignup() {
      this.$emit("change-view", "signup-from-confirmation");
    },
    signUp() {
      this.loading = true;
      this.resetErrors();

      AngelaAPI.register(this.registration)
        .then((response) => {
          this.loading = false;
          this.registerSuccess = true;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.$refs.errorBox.setError(
              "Looks like you already registered this email address."
            );
            this.loading = false;
            return;
          }
          this.loading = false;
          if (error.response.status === 400) {
            const errors = error.response.data.data;

            for (var i = 0; i < errors.length; i++) {
              const message = ErrorMessages.get(errors[i].error);
              for (var r = 0; r < this.fields.length; r++) {
                if (this.fields[r].field === errors[i].field) {
                  this.$refs[this.fields[r].ref].setError(message);
                  break;
                }
              }
            }

            let errorMessage =
              "Something went wrong, please check the errors below!";
            const agreementError = errors.find((item) => {
              return item.error === "AGREEMENTS_REQUIRED";
            });

            if (agreementError) {
              errorMessage =
                "You must agree to our Terms in order to use the Mercala dashboard";
            }

            this.$refs.errorBox.setError(errorMessage);
          } else {
            this.$refs.errorBox.setError(
              "Something went wrong, please try again later or contact us."
            );
          }
        });
    },
    resendConfirmation() {
      this.confirmationSuccess = false;
      this.confirmationMessage.showEmailInput = false;
      AngelaAPI.resendConfirmationEmail(this.confirmationMessage.email)
        .then((response) => {
          this.confirmationMessage.title = "Done!";
          this.confirmationMessage.text =
            "Check your email inbox or spambox for a new link!";
          this.confirmationMessage.signup = false;
          this.confirmationSuccess = true;
        })
        .catch((error) => {
          this.confirmationMessage.title = "Oh!";
          this.confirmationMessage.text =
            "It appears your account has been closed. Please sign up for a new account.";
          this.confirmationMessage.signup = true;
          this.confirmationSuccess = true;
        });
    },
    resendMail() {
      AngelaAPI.resendConfirmationEmail(this.registration.email)
        .then((response) => {
          this.emailResend = true;
          setTimeout(() => {
            this.emailResend = false;
          }, 5000);
        })
        .catch((error) => {
          this.$refs.errorBox.setError(
            "Something went wrong, please try again later or contact support."
          );
        });
    },
    backToLogin() {
      this.resetErrors();
      this.currentForm = "contact-form";
      if (this.confirmation) {
        this.confirmationSuccess = false;
        this.$router.replace({ path: "/" });
      }
      setTimeout(() => {
        this.registerSuccess = false;
      }, 250);
      this.$emit("change-view", "signin");
    },
    resetErrors() {
      if (this.$refs.errorBox) {
        this.$refs.errorBox.clearError();
        // Reset all fields
        for (var i = 0; i < this.fields.length; i++) {
          if (this.$refs[this.fields[i].ref]) {
            this.$refs[this.fields[i].ref].setError("");
          }
        }
      }
    },
    breadcrumbClasses(step) {
      return {
        "crumb-active": this.currentStep >= step,
      };
    },
    resendTextClasses() {
      return {
        "home-register-success-resend": true,
        "home-register-success-resend-done": this.emailResend,
      };
    },
    confirmingClasses() {
      return {
        "home-register-success-confirming": true,
        "home-register-success-confirming-done": this.confirmationSuccess,
      };
    },
    confirmedClasses() {
      return {
        "home-register-success-confirmed": true,
        "home-register-success-confirmed-active": this.confirmationSuccess,
      };
    },
  },
  mounted() {
    if (this.$props.confirmation) {
      setTimeout(() => {
        AngelaAPI.confirmEmail(this.$props.token)
          .then((response) => {
            this.confirmationMessage.title = "Success!";
            this.confirmationMessage.text =
              "Your account has been activated <br/> You can now sign in to use your account.";
            this.confirmationMessage.showEmailInput = false;
            this.confirmationSuccess = true;
          })
          .catch((error) => {
            this.confirmationMessage.title = "Oops...";
            this.confirmationMessage.text =
              "This confirmation email link has expired. <br/> Please fill in your email below to send a new link.";
            this.confirmationMessage.showEmailInput = true;
            this.confirmationSuccess = true;
          });
      }, 2000);
    } else {
      window.addEventListener("keyup", this.onEnterKey);
    }
  },
  destroyed() {
    window.removeEventListener("keyup", this.onEnterKey);
  },
};
</script>

<style lang="scss">
@import "../../styles/components/home/RegisterForm";
</style>
