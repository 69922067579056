<template>
  <div class="reset-password">
    <img src="../assets/images/Mercala_logo_red.svg" alt="Mercala Logo" />

    <template v-if="this.tokenExpired">
        <h3>Reset link expired</h3>
        <p>Head back home and request a new reset password link!</p>
        <MercalaButton 
            v-on:button-event="toHome()"
            label="Back home"
            type="ok" />
    </template>

    <template v-if="!this.success && !this.tokenExpired">
        <h3>Your new password</h3>

        <TextInput 
            ref="password"
            placeholder="New Password"
            inputType="password"
            inputName="password"
            v-model="reset.password" />
        
        <TextInput 
            ref="passwordConfirm"
            placeholder="Confirm Password"
            inputType="password"
            inputName="passwordConfirm"
            v-model="reset.passwordConfirm" />

        <img v-show="loading" class="spinner" src="../assets/images/spinner.svg" />

        <MercalaButton 
            v-show="!loading"
            v-on:button-event="sendReset()"
            label="Reset password"
            type="ok" />
        
        <p v-show="(errorMessage != '')">{{ errorMessage }}</p>
    </template>

    <template v-if="this.success && !this.tokenExpired">
        <h3>Done!</h3>
        <p>Your new password is set and you have been logged out from all devices.</p>
        <p>Head over to the login page with your new password!</p>
        <MercalaButton 
            v-on:button-event="toHome()"
            label="Let's head back."
            type="ok" />
    </template>
    
  </div>
</template>

<script>
import MercalaButton from '@/components/form/MercalaButton'
import TextInput from '@/components/form/TextInput'
import AngelaAPI from '@/utils/AngelaAPI'
import ErrorMessages from '@/utils/ErrorMessages'
export default {
    name: 'NotFound',
    components: {
        MercalaButton,
        TextInput
    },
    data(){
        return {
            errorMessage: '',
            loading: false,
            success: false,
            tokenExpired: true,
            token: '',
            reset: {
                password: '',
                passwordConfirm: ''
            }
        };
    },
    mounted(){
        if(Object.keys(this.$route.params).includes('token')){
            this.tokenExpired = false;
            this.token = this.$route.params.token;
        }
    },
    methods: {
        sendReset(){
            this.loading = true;
            this.errorMessage = '';
            this.$refs.password.setError('');
            this.$refs.passwordConfirm.setError('');
            AngelaAPI.resetPassword(this.token, this.reset).then((response) => {
                this.loading = false;
                this.success = true;
            })
            .catch((error) => {
                this.loading = false;
                if(error.response.status === 401){
                    this.tokenExpired = true;
                }else{
                    this.success = false;
                    const errors = error.response.data.data;
                    for(var i = 0; i < errors.length; i++){
                        const message = ErrorMessages.get(errors[i].error)
                        switch(errors[i].field){
                            case 'password':
                                this.$refs.password.setError(message)
                            break;
                            case 'password_confirmation':
                                this.$refs.passwordConfirm.setError(message)
                            break;
                        }
                    }
                    this.errorMessage = 'Something went wrong';
                }
            });
        },
        toHome(){
            this.$router.replace('/');
        }
    }
}
</script>

<style lang="scss">

.reset-password{
    width: 350px;
    margin: 0px auto;
    text-align: center;

    .spinner{
        display: block;
        margin: 5px auto 0px auto;
        width: 75px;
    }

    h3{
        font-weight: 600;
        margin-bottom: 25px;
        color: #FF1E1E;
        font-size: 2rem;
    }

    p{
        line-height: 1.5rem;
        color: black;
        font-size: 1rem;
        margin-bottom: 25px;
    }
  
    img{
        width: 200px;
        margin: 50px auto 25px auto;
    }

    .form-text-input{
        text-align: left;;
    }

    .mercala-button{
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

</style>
