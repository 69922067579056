import Vue from 'vue'
import Vuex from 'vuex'
import AngelaAPI from '@/utils/AngelaAPI'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    settings: {
      dashboard: {
        loaded: false,
        trial_expired_text: '',
        overdue_text: '',
        terms_of_use: '',
        privacy_policy: '',
        subscription_expired_text: ''
      }
    },
    auth: {
      authenticated: false,
      token: localStorage.getItem('mercala-user-auth-token'),
      user: {
        email: '',
        status: '',
        subscription_status: '',
        subscription_type: '',
        subscription_remaining_days: ''
      }
    },
    filterOptions: {
      parcelTypes: [
        { name: 'All Rights to Land', val: '' },
        { name: 'Freehold', val: 'property land' },
        { name: 'Leasehold', val: 'long lease land' },
      ],
      propertyTypes: [
        { name: 'All Property Types', val: '' },
        { name: 'Land', val: 'Land' },
        { name: 'House', val: 'House' },
        { name: 'Apartment', val: 'Apartment' },
        { name: 'Commercial', val: 'Commercial' },
        { name: 'Condominium', val: 'Condominium' }
      ],
      prices: [
        { name: 'All Prices', val: '' },
        { name: 'Less than 200 k', val: '0-200000' },
        { name: '200 k - 300 k', val: '200001-300000' },
        { name: '300 k - 400 k', val: '300001-400000' },
        { name: '400 k - 500 k', val: '400001-500000' },
        { name: '500 k - 600 k', val: '500001-600000' },
        { name: '600 k - 700 k', val: '600001-700000' },
        { name: '700 k - 800 k', val: '700001-800000' },
        { name: '800 k - 900 k', val: '800001-900000' },
        { name: 'More than 900 k', val: '900001-999' }
      ],
      rentalPrices: [
        { name: 'All prices', val: '' },
        { name: 'Less than 1k', val: '0-1000' },
        { name: '1 k - 2 k', val: '1001-2000' },
        { name: '2 k - 3 k', val: '2001-3000' },
        { name: '3 k - 4 k', val: '3001-4000' },
        { name: '4 k - 5 k', val: '4001-5000' },
        { name: '5 k - 6 k', val: '5001-6000' },
        { name: '6 k - 7 k', val: '6001-7000' },
        { name: '7 k - 8 k', val: '7001-8000' },
        { name: 'More than 8 k', val: '8001-999' }
      ],
      bedrooms: ['1', '2', '3', '4', '5+'],
      bathrooms: ['1', '2', '3', '4', '5+'],
      daysListed: ['7', '30', '90', '180', '365'],
      countries: [
        { name: 'No island', val: '' },
        { name: 'Aruba', val: 'Aruba' },
        { name: 'Bonaire', val: 'Bonaire' },
        { name: 'Curacao', val: 'Curacao' }
      ],
      districts: [],
      agencies: [],
      contracts: [
        { name: 'All Contracts', val: '' },
        { name: 'For Sale', val: 'sale' },
        { name: 'For Rent', val: 'rent' },
      ]
    },
    formValues: {
      selectedSavedFilter: {
        name: ''
      },
      contract: 'sale',
      appliedFilters: {
        country: '',
        district: '',
        districts: '',
        agency: '',
        bedrooms: '',
        bathrooms: '',
        reduced: '',
        increased: '',
        daysListed: '',
        priceRange: {
          min: "",
          max: ""
        },
        maxPrice: '',
        pool: '',
        propertyType: '',
        parcelType: "",
        lotSize: {
          min: "",
          max: ""
        }
      },
      sorting: {
        sortBy: '',
        sortDirection: ''
      },
      pagination: {
        page: 1,
        limit: 50
      }
    }
  },
  getters: {
    getAgreements: (state) => (refreshText) => {
      if (state.settings.dashboard.loaded && !refreshText) {
        return new Promise((resolve, reject) => {
          resolve(state.settings.dashboard);
        });
      } else {
        return AngelaAPI.getAgreements().then((response) => {
          state.settings.dashboard = response.data.data;
          return new Promise((resolve, reject) => {
            resolve(state.settings.dashboard);
          });
        })
          .catch((error) => {
            // Nothing to handle
          });
      }
    },
    getDashboardSettings: (state) => (refreshText) => {
      if (state.settings.dashboard.loaded && !refreshText) {
        return new Promise((resolve, reject) => {
          resolve(state.settings.dashboard);
        });
      } else {
        return AngelaAPI.getDashboardSettings().then((response) => {
          state.settings.dashboard = response.data.data;
          return new Promise((resolve, reject) => {
            resolve(state.settings.dashboard);
          });
        })
          .catch((error) => {
            // Nothing to handle
          });
      }
    },
    getFilterPrices: (state) => (noPrice) => {

      let prices = Array.from(state.filterOptions.prices);
      if (state.formValues.contract === "rent") {
        prices = Array.from(state.filterOptions.rentalPrices);
      }

      prices[0] = {
        name: noPrice,
        val: ''
      };
      return prices;
    },
    getFilterPropertyTypes: (state) => { return state.filterOptions.propertyTypes; },
    getFilterParcelTypes: (state) => { return state.filterOptions.parcelTypes; },
    getFilterBedrooms: (state) => { return state.filterOptions.bedrooms; },
    getFilterBathrooms: (state) => { return state.filterOptions.bathrooms; },
    getFilterDaysListed: (state) => { return state.filterOptions.daysListed; },
    getFilterCountries: (state) => { return state.filterOptions.countries; },
    getFilterContracts: (state) => { return state.filterOptions.contracts; },
    getFilterDistricts: (state) => {
      if (state.filterOptions.districts.length !== 0) {
        return new Promise((resolve, reject) => {
          resolve(state.filterOptions.districts);
        });
      } else {
        return AngelaAPI.listDistricts().then((response) => {
          const districts = response.data.data.map(district => {

            let labelName = district.name;
            if (labelName === "Noord/Tanki Leendert") {
              labelName = " Noord Tanki Leendert";
            }

            return {
              name: labelName,
              val: district.name
            };
          });
          state.filterOptions.districts = districts;

          return new Promise((resolve, reject) => {
            resolve(state.filterOptions.districts);
          });
        })
          .catch((error) => {
            // Nothing to handle
          });
      }
    },
    getFilterAgencies: (state) => {
      if (state.filterOptions.agencies.length !== 0) {
        return new Promise((resolve, reject) => {
          resolve(state.filterOptions.agencies);
        });
      } else {
        return AngelaAPI.listAgencies().then((response) => {
          const agencies = response.data.data.map(agency => {
            return { name: `${agency.name} (${agency.houses_count})`, val: agency.name };
          });
          agencies.unshift({ name: 'All Agencies', val: '' });
          state.filterOptions.agencies = agencies;

          return new Promise((resolve, reject) => {
            resolve(state.filterOptions.agencies);
          });
        })
          .catch((error) => {
            // Nothing to handle
          });
      }
    },
    getFormValues: (state) => (form) => { return state.formValues[form]; },
    getUserData: (state) => { return state.auth.user; }
  },
  mutations: {
    setAuthenticationToken(state, token) {
      localStorage.setItem('mercala-user-auth-token', token);
      state.auth.token = token;
    },
    setAuthenticatedUser(state, user) {
      state.auth.user = user;
    },
    setFormValues(state, data) {
      state.formValues[data.form] = data.val;
    }
  },
  actions: {
  },
  modules: {
  }
})
