<template>
  <div class="form-checkbox-button">
    <input
      ref="checkbox"
      :id="getInputId()"
      type="checkbox"
      :name="inputName"
      v-model="selectedValue"
      @change="updateValue"
    />

    <label :for="getInputId()">
      <div class="form-checkbox-button-icon">
        <img
          v-show="selectedValue"
          src="../../assets/images/icons/checkbox_icon.svg"
          alt="Checkbox icon"
        />
      </div>
      {{ inputLabel }}
    </label>

    <p v-show="errorMessage != ''">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    inputName: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: "",
      selectedValue: false,
    };
  },
  methods: {
    triggerCheckbox() {
      if (this.$refs.checkbox.checked) {
        this.$refs.checkbox.checked = false;
      } else {
        this.$refs.checkbox.checked = true;
      }
    },
    getInputId() {
      return `checkbox-button-${this.$props.inputName}`;
    },
    updateValue(event) {
      this.selectedValue = event.target.checked;
      this.$emit("input", event.target.checked);
    },
    setError(message) {
      this.errorMessage = message;
    },
    setValue(val) {
      this.selectedValue = val;
    },
    resetValue() {
      this.selectedValue = false;
    },
  },
};
</script>

<style lang="scss">
.form-checkbox-button {
  position: relative;
  margin-top: 10px;

  input {
    display: none;
    &:checked + span {
      background: rgb(255, 30, 30);
      background: linear-gradient(
        -90deg,
        rgba(255, 30, 30, 1) 0%,
        rgba(254, 90, 51, 1) 100%
      );
      color: white;
    }
  }

  p {
    margin-top: 5px;
    margin-bottom: 15px;
    color: red;
  }

  label {
    font-weight: 100;
    font-size: 0.75rem;
    line-height: 1.3rem;
    color: black;
    cursor: pointer;
    padding-left: 30px;
  }

  &-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: #f3f3f3;

    img {
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }
}
</style>
