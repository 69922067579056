<template>
  <div class="form-text-input">
    <label v-show="inputLabel != ''" :for="getInputId()">{{
      inputLabel
    }}</label>

    <input
      ref="textInput"
      :id="getInputId()"
      :type="inputType"
      :value="inputValue"
      :class="inputClasses()"
      v-model="localValue"
      v-on:input="updateValue($event)"
      @keyup.enter="enterEvent()"
    />

    <span
      v-on:click="focusInput()"
      v-show="inputLabel == ''"
      :class="innerLabelClasses()"
      >{{ placeholder }}</span
    >

    <p v-show="errorMessage != ''">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    inputLabel: {
      type: String,
      default: "",
    },
    inputName: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      required: true,
      default: "text",
    },
    inputClass: {
      type: String,
      default: "default",
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      localValue: "",
      errorMessage: "",
    };
  },
  methods: {
    enterEvent() {
      this.$emit("on-enter");
    },
    focusInput() {
      this.$refs.textInput.focus();
    },
    getInputId() {
      return `text-input-${this.$props.inputName}`;
    },
    innerLabelClasses() {
      return {
        "inner-label": true,
        "inner-label-filled": this.localValue !== "",
        "inner-label-default": this.$props.inputClass === "default",
        "inner-label-dashboard": this.$props.inputClass === "dashboard",
      };
    },
    updateValue(event) {
      this.localValue = event.target.value;
      this.$emit("input", event.target.value);
    },
    setError(message) {
      this.errorMessage = message;
    },
    setValue(val) {
      this.localValue = val;
    },
    getLocalValue() {
      return this.localValue;
    },
    resetValue() {
      this.localValue = "";
    },
    inputClasses() {
      return {
        "input-style": true,
        "input-style-default": this.$props.inputClass === "default",
        "input-style-dashboard": this.$props.inputClass === "dashboard",
      };
    },
  },
};
</script>

<style lang="scss">
.form-text-input {
  width: 100%;
  margin-top: 10px;
  position: relative;

  p {
    margin-top: 5px;
    margin-bottom: 15px;
    color: red;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  label {
    font-weight: 450;
    font-size: 0.875rem;
  }

  .input-style {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    padding: 12px 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    font-size: 0.875rem;

    &:focus ~ span {
      transform: translate(-12px, -12px);
      font-size: 0.5rem;
    }

    &-default {
      height: 50px;
    }

    &-dashboard {
      height: 40px;
    }
  }

  .inner-label {
    position: absolute;
    left: 20px;
    font-size: 0.875rem;
    color: #979797;
    transition: transform 0.2s, font-size 0.2s linear;

    &-default {
      top: 22px;
    }

    &-dashboard {
      top: 18px;
    }

    &-filled {
      transform: translate(-12px, -12px);
      font-size: 0.5rem;
    }
  }
}
</style>
