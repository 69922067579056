<template>
  <div class="home-reset">
    <div class="home-reset-back">
      <ArrowButton
        v-on:button-event="backToLogin()"
        label="Sign in"
        arrowDirection="left"
      />
    </div>

    <div class="home-reset-container">
      <h2>Reset password</h2>

      <ErrorBox ref="errorBox" />

      <TextInput
        placeholder="Email"
        inputName="email"
        inputType="text"
        v-model="email"
      />

      <div class="home-reset-button">
        <img
          v-show="loading"
          class="spinner"
          src="../../assets/images/spinner.svg"
        />

        <MercalaButton
          v-on:button-event="sendReset()"
          label="Send reset request"
          type="ok"
        />
      </div>

      <p :class="resendTextClasses()">
        We have send you a reset password link. Please check your inbox or
        spambox!
      </p>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import MercalaButton from "@/components/form/MercalaButton";
import ArrowButton from "@/components/form/ArrowButton";
import ErrorBox from "@/components/form/ErrorBox";
import AngelaAPI from "@/utils/AngelaAPI";

export default {
  name: "ResetForm",
  components: {
    TextInput,
    MercalaButton,
    ArrowButton,
    ErrorBox,
  },
  data() {
    return {
      loading: false,
      email: "",
      sendState: false,
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onEnterKey);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onEnterKey);
  },
  methods: {
    onEnterKey(event) {
      if (event.keyCode === 13) {
        this.sendReset();
      }
    },
    sendReset() {
      this.$refs.errorBox.clearError();
      this.loading = true;

      AngelaAPI.sendResetPasswordMail(this.email)
        .then((response) => {
          this.sendState = true;
          this.loading = false;
          setTimeout(() => {
            this.sendState = false;
          }, 10000);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 404) {
            this.$refs.errorBox.setError(
              "There is no account associated with this email."
            );
          } else {
            this.$refs.errorBox.setError(
              "Something went wrong, please try again later."
            );
          }
        });
    },
    backToLogin() {
      this.$emit("change-view", "signin");
    },
    resendTextClasses() {
      return {
        "home-reset-container-resend": true,
        "home-reset-container-resend-done": this.sendState,
      };
    },
  },
};
</script>

<style lang="scss">
.home-reset {
  width: 100%;
  padding-left: 75px;
  padding-right: 75px;
  margin: 200px auto 0px auto;
  position: relative;

  &-button {
    display: block;
    margin: 10px 0px 0px auto;
    position: relative;

    .spinner {
      position: absolute;
      width: 60px;
      right: 226px;
      top: 14px;
    }

    .mercala-button {
      display: inline-block;
    }
  }

  .error-box {
    margin-top: 15px;
  }

  &-back {
    position: absolute;
    top: -75px;
    left: 105px;
  }

  &-container {
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;

    .mercala-button {
      margin-left: auto;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &-resend {
      color: #ff1e1e;
      opacity: 0;

      &-done {
        animation-name: emailResendAnim;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
      }
    }
  }
}

@keyframes emailResendAnim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
